.carousel-container {
    /* width: 100%; */
    background-color: rgb(212, 209, 209);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left:40px;
    overflow: hidden;
  }
  
  .logo-carousel {
    display: flex;
    animation: marquee 35s linear infinite;
  }
  
  .logo-carousel img {
    /* width: 100px;  */
    width: 200px;
    height: 150px;
    box-shadow:1px 1px 4px 1px;
    border-radius: 10px;
    color:rgb(222, 224, 226);
    margin: 0 20px; 
    
  }

  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-360%);
    }
  }
  