.carou-bg{
    background-color: whitesmoke;
}
.carousel-img{
    width: 120px;
    height: 120px;
    border: 1px solid #FF8F27;
    border-radius: 70px;
    
}
/* .colr{
    color:#FF8F27;
    background-color: #FF8F27;
} */
.carousel-style{
    position: relative;
    /* background-color: #FF8F27; */
}

.carousel-positioning{
    position: absolute;
    top: 100%; 
    left:569px;
    padding:2px 8px;
    display: flex;
}
.c-style{
    background-color: #f81818;
    color: #FF8F27;
    /* width: 15px;
    height: 15px; */
    margin: 5px;
    padding:1px 20px;
    border:none;
}
.c-style.active{
    background-color: #FF8F27; 

}

.icon-style{
    color: #FF8F27;
    font-size: 17px;
}
