.sizing{
    padding-bottom:150px;
}

.bg-location{
    background-color: white;
   color: black;
    
}
@media screen and (max-width: 700px) {
    .bg-location{
        background-color: white;
        width: 100%;
        padding-left: 15px;
        padding-top: 15px;
       color: black;
    }
    .lottie-animation{
        width: 100%;
        height: 400px;
        background-color: white;
    }
  }