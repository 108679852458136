body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Condensed',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(247, 244, 244); */
  background-color: rgb(250, 244, 244);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fontColor{
  color:  #FF8F27;
}
.bg_color{
  background-color: #FF8F27;

}

/* not found */
.error-text {
  color:#0c2685;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.error-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
