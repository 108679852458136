.service-bg-color{
    background-color: rgb(245, 243, 243);
}
.background-companyProfile{
    background: rgba(0,0,0,0.8) url(../../images/service-bg.jpg);
    background-size: cover;
    background-blend-mode: darken;
    width: 100%;
    height: 400px;
    opacity: 0.9;
    
}
@media only screen and (max-width: 600px) {
    .background-companyProfile{
        background: rgba(0,0,0,0.8) url(../../images/service-bg.jpg);
        background-size: cover;
        background-blend-mode: darken;
        width: 100%;
        height: 300px;
        opacity: 0.9;
        
    }
  }
@media only screen and (max-width: 500px) {
    .background-companyProfile{
        background: rgba(0,0,0,0.8) url(../../images/service-bg.jpg);
        background-size: cover;
        background-blend-mode: darken;
        width: 100%;
        height: 150px;
        opacity: 0.9;
        
    }
  }
.company-text{
    padding-top: 10%;
    text-align: center;
}
.company{
    border-bottom: 2px solid #FF8F27;
    padding:5px 15px;
}
.service-color{
    color:#FF8F27;
}