.bgColor{
    /* background-color: #0d0c29; */
    /* background-color: #21232F; */
    background-color: rgb(30,48,80);
}
.bgColor2{
    background-color: rgb(8, 73, 134);
}

.home-footer{
    margin-left: 300px;
}
.footer-container{
    padding-top: 150px;
    padding-bottom: 150px;
}
.footer-copyright{
    color:white;
    padding: 0px 0px 30px 100px;
}
.txt-color{
    color: rgba(11, 130, 241, 0.87);
}
.newsletter{
    background-color: rgb(0,128,215);
    justify-content: center;
    align-items: center;
    color:white;
    position: relative;
    
}
.newsletter-form{
    margin-bottom: -100px;
}
input{
    padding: 15px;
    margin-right:5px;
    border-radius: 10px;
    border:none;
}
.submit-btn{
    background-color: rgb(12, 65, 116);
    color: white;
}
.social-info{
    border: none;
    padding: 15px 20px;
    margin-right: 10px;
    background-color: rgb(82, 78, 78);
    color:white;text-decoration: none;
}
.social-info:hover{
    background-color: #FF8F27;
}
.icon-sizing{
    font-size: 20px;
    text-decoration: none;
    color:white;
}
a{
    text-decoration: none;
}
.hover-effect li:hover{
    color:#FF8F27;
}