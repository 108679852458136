.ideology-btn{
    background-color: #FF8F27;
    color:white;
    border:none;
    padding: 10px 40px;
    border-radius:  10px;
    
}
.ideology-btn:hover{
    background-color: black;
    color: white;
}

.ideology-btn:hover .rotation-prop{
    rotate: 360deg;
    transition: 2s;
}