/* .progressBar{
    color:red;
    background-color: rgb(146, 83, 83);
    animation: progress-animation 5s forwards;
} */

/* @keyframes progress-animation{
    0% {width: 0%;}
    100%{width: 100%;} */
/* } */

.custom-progress-bar {
    background-color: white; 
    color:red;
    font-variant: red;
    }

    .custom-progress-bar .progress-bar {
        background-color: #ff8f27; }