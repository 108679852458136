.close-btn{
    border: none;
    border-radius: 10px;
    padding: 10px 40px;
    margin-top: 50px;
    margin-left: 440px;
}
.fnt-awesome-clsBtn{
    margin-top: 120px;
    font-size: 30px;
    color: #f8f6f6;

}
.video-styling{
    margin-top: 40px;
    padding-bottom: 20px;
}

.styled-videos{
        position: fixed;
        margin-top: 150;
        margin-bottom: 20;
        margin-left: 200;
        margin-right: 200;
        background-color: 'rgba(0, 0, 0, 0.5)';
        /* display: 'flex'; */
        justify-content: 'center';
        align-items: 'center';
}