.header-style{
    background-color: rgb(29, 28, 28);
}
.navbar-toggler{
    text-align: left;
}

.dropdown-menu .dropdown-li{
    color:white;
    padding: 15px 15px;
}
.dropdown-menu .dropdown-li:hover{
    color:white;
    background-color: #0c2685;
  
}
.dropdown-menu .dropdown-li:hover .dropdown-plus{
    color:white;
}
.dropdown-plus{
    color:black;
}


.dropdown:hover .dropdown-menu{
    display: block;
}
.dropdown .dropdown-menu{
    display:none;
}

.dropdown-item a{
    color:black;
}
.dropdown-item:hover{
    color:white;
}
.anchor-style a{
    color:white;
    text-decoration: none;
}
.custom-bg-color:hover {
    background-color: #0c2685;
    color: #ffffff; /* Optionally, set the text color for better visibility */
  }
.btn-style{
    background-color: #0c2685;
    color:white;
    border:none;
    font-size: 15px;
    padding: 8px 15px;
    border-radius: 5px;
}

.dropdown{
    text-align: left;
}


.nav-style2{
    padding-left: 450px;
}

.para-class {
    padding-left: 10px;
    padding-top:18px;
    font-size: 12px;
    line-height: 140%;
}
.colorScheme{
    color:black;   
}

.fontColor:hover{
    color: #FF8F27;
}
.hover-style{
    width: 300px;
    padding: 20px;
}
.hover-style:hover{
     background-color: rgb(12, 12, 75); ;
    color:white;
    transition: 1s;
   
}
.source-img{
    width: 170px;
    height: 60px;
   
}

.royal_blue{
    color: #4169e1;
}

.social-info3{
    border-right: 1px solid rgb(94, 77, 77);
    padding: 15px 20px;
    background-color: black;
    text-decoration: none;

}
.social-info3:hover{
    background-color: #FF8F27;
    transition: 1s;
}
.icon-sizing3{
    font-size: 20px;
    text-decoration: none;
    color:white;
}

.nav-style{
    border-bottom: 1px solid rgb(175, 169, 169);
}

.header-color{
    background-color: white;
}
.header-hover{
    font-weight: bold;
}


.header-hover:hover{
    color: #0c2685;
   
}
.bg-admin{
    color: #676768;;
    width: 35px;
    height: 35px;
}
.custom-header{
    color:black;
}



.header-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 5s linear infinite;
  
    
  }
  
  
  .header-rotate-image  {
    position: relative;
    z-index: 2;
    height: 80px;
    width: 80px;
    border-radius: 30px;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
          transform-origin: center;

    }
    to {
      transform: rotate(360deg);
          transform-origin: center;

    }
  }
  