.scroll-button {
    position: fixed;
    bottom: 90px;
    right: 5px;
  }
  
  .scroll-button button {
    color: rgb(12, 12, 75);
    border: 2px solid rgb(12, 12, 75);
    border-radius: 40px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 20px;
    }
 

.button-container {
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.moving-button {
  margin-top: 0;
  animation: moveButton 5s linear infinite;
}

@keyframes moveButton {
  0% {
    margin-top: 0;
  }
  25% {
    margin-top: 40px;
  }
  50% {
    margin-top: 0;
  }
  75% {
    margin-top: -20;
  }
  100% {
    margin-top: 0;
  }
  
  
}
