.about{
    background-color: rgb(243, 234, 234);
}

.about-cover{
    width: 100%;
    height: 300px;
     opacity: .7;
    position: relative; 

} 
.about-us{
    text-align: center;
    background-color: rgb(30,48,80);
    padding: 8px 15px;
    border-radius: 10px;
    color: white;
    font-size: 35px;
    justify-content: center;
}
.about-header{
    color:rgb(30,48,80);
    font-size:  35px;
    font-weight: bold;
    
}

.about-container{
    background-color: white;
   padding: 10px;
   margin-bottom: 5px;
   
   
}

.header-scale{
    filter: grayscale(100%);
    color:rgb(179, 175, 175);
    font-size: 70px;
    background-color: red;
    border-radius: 70px;
    width: 40%;
    padding: 15px 25px;
    text-align: center;

}

.about-container:hover .header-scale{
    filter: grayscale(0%);
    color:#FF8F27;
    background: linear-gradient(to left, rgb(12, 12, 75) 50%, white 50%) right;
    transition: 2s;
    background-size: 200% 100%;
}

.main-head{
    color:rgb(12, 12, 75);
    font-weight: bold;
}

.about-background{
    background-color: rgb(30,48,80);
}
.iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    /* padding-top: 5.25%; */
    margin: 0 auto;
    max-width: 100%; /* Ensure the container doesn't exceed its parent's width */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .about-global{
    padding-top: 5.25%;
    color:white;
  }
  .about-hr{
    border: 1px solid #fd7e14;
  }
.project-starting{
    
    justify-content: center;
    align-items: center;
    background-color: rgb(208, 214, 224);

}
.contact-button{
    background-color: white;
    color:rgb(30,48,80);
    font-size: 25px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;

  }
  .about-research{
    width: 120px;
    border-radius: 170px;
  }

  .background-about{
    background: rgba(0,0,0,0.8) url(../../images/teamCover4.jpg);
    background-size: cover;
    background-blend-mode: darken;
    opacity: 0.9;
    width: 100%;
  height: 320px; 
  background-position: center;
  background-repeat: no-repeat; 
}
@media only screen and (max-width: 500px) {
  .background-about{
    background: rgba(0,0,0,0.8) url(../../images/teamCover4.jpg);
    background-size: cover;
    background-blend-mode: darken;
    opacity: 0.9;
    width: 100%;
  height: 200px; 
  background-position: center;
  background-repeat: no-repeat; 
}
.aboutt-text{
  padding-top: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

.aboutt-text{
    padding-top: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-about{
    border-bottom: 2px solid rgb(216, 199, 199);
    padding:5px 15px;
   
}