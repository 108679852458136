.contact-container{
    justify-content: center;
    grid-gap: 20px;
}

.contact-card{
    background-color: white;
    width: 400px;
    height: 200px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.contact-card:hover .contact-icons{
    rotate: 360deg;
    transition: 2s;
}
.contact-icons{
    font-size: 25px;
    color:#FF8F27;
    
    padding: 23px 25px;
    border-radius: 60px;
}
.contact-card:hover .contact-icons{
    background-color: rgb(12, 12, 75);
    transition: 1s;
}

/* form */
.contact-img{
    /* width: 100%; */
    height: 500px;
}

.form-width{
    width: 49%;
}
.pcontact-btn{
    
    background: rgb(30,48,80);
    color: white;
    font-size: 55px;
    font-weight: bold;
    width: 100%;
    font-size: 20px;
    border: 1px solid black;
    
    
}
.pcontact-btn:hover{
    color:white;
    transition: 2s;
    background-size: 200% 100%;
  

}
.contact-font{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    background-color:rgb(30,48,80);
    color:white;
    padding:  10px 12px;
    border-radius: 10px;

}