
.img-container{
    position: relative;  
}

.para-style1{
    position: absolute;
    top: 50px;
    left: 150px;
    width: 500px;
    padding: 35px;
    color:rgb(253, 250, 250);
    
}
.para-style{
    position: absolute;
    top: 50px;
    left: 10%;
    padding: 35px;
    color:rgb(253, 250, 250);
}
.btn-carousel{
    border:none;
    padding: 8px 14px;
    border-radius: 8px;
    font-weight: bold;
}

.img-style{
    height: 620px;
}
@media only screen and (max-width: 576px) {
    .img-style{
        height: 300px;
    }
    .para-style1{
        position: absolute;
        top: 50px;
        left: 15px;
        width: 350px;
        padding: 35px;
        color:rgb(253, 250, 250);
        
    }
  }
@media only screen and (max-width: 768px) {
    .img-style{
        height: 400px;
    }
  }
@media only screen and (max-width: 992px) {
    .img-style{
        height: 500px;
    }
  }

.para-style h5{
    color: #FF8F27;
}
.caro-btn{
    background-color: #FF8F27;
    color:white;
    border:none;
    padding: 10px 40px;
    border-radius:  10px;
}
.caro-btn:hover{
    background-color: white;
    color: black;
}

.bg-service{
    background-color: #f8f6f6;
}
.img-style2{
    width: 400px;  
    height: 500px; 
}
.service-cont{
    background-color: white;
}
.extended-size{
    padding-top: 120px;
    padding-bottom: 80px;
}


