
.carousel-img2{
    width: 100%;
    height: 300px;
    border-radius: 5px;
}
.carousel-img2:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.); 
    transition: 1.5s;
}

.carousel-style2{
    position: relative;
    background-color: #FF8F27;
}

.carousel-positioning2{
    position: absolute;
    top: 100%; 
}
.c-style2{
    background-color: #f81818; 
    width: 15px; 
    height: 15px;
     
    margin: 5px;
}
.c-style.active2{
    background-color: #FF8F27; /* Replace with your desired background color */

}

.icon-style2{
    color: #FF8F27;
    font-size: 17px;
}
.social-info2{
    border: none;
    padding: 15px 20px;
    margin-right: 10px;
    background-color: rgb(82, 78, 78);
    color:white;text-decoration: none;
}
.social-info2:hover{
    background-color: #FF8F27;
    transform: 1s;
}
.icon-sizing2{
    font-size: 20px;
    text-decoration: none;
    color:white;
}
a{
    text-decoration: none;
}

/* team  */

.teamCoverText{
    color:white;
    border:1px solid black;
    text-align: center;
    border-radius: 50px;
    padding: 15px 25px;
    font-size: 35px;
    background-color: rgb(30,48,80);
}

.coreTeamCard{
    background-color: white;
    padding-top:10px;
    text-align: center;
    margin-bottom:20px;
}
.coreTeamCard:hover .teamInfo{
    border-radius: 0px;
    transition: 1s;
}

.teamMembersImg{
    width: 80%;
    height: 50%;
    border-radius: 5px;
}
.otherTeamMembersImg{
    width: 100%;
    height: 320px;
    border-radius: 5px;
}
.teamInfo{
    background-color: #101e52;
    color:white;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    padding-top: 6px;
    /* padding-left:15px;
    padding-right:15px; */
    padding-bottom: 2px;
    margin-top: 10px;
   
}
.coreTeamInfo{
    background-color: #101e52;
    color:white;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 2px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
}
.coreTeamCard:hover .coreTeamInfo{
    border-radius: 0px;
    transition: 1s;
}



/* advisor */
.advisor-img{
    width: 300px;
    height: 300px;
    margin:10px;
    background-color: whitesmoke;
    box-shadow: 1px 2px 3px 1px gray;
    border-radius: 10px;
}
.advisor-header{
    border: 1px solid black;
}
.advisors-info{
    background-color: white;
    margin-top:10px;
    margin-bottom: 15px;
}

.team-icon{
    width: 40px;
    height: 40px;
    margin: 10px;
    padding: 10px;
    color:rgb(30,48,80);
}
.team-icon:hover{
    background-color:none;
}
.custom-font{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
}