.faq-img{
    width: 600px;
    height: 500px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
}
.img-wid{
    width: 50%;
}
.accordion-button{
   
    display: flex;
flex-wrap: wrap;
}