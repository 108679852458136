

.service-section{
    background-color: #054e70f1;
    color:white;
    
}
.social-sites{
   text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 50%;
    background-color: rgb(8, 73, 134);
    box-shadow: 5px 5px 2px rgb(0, 14, 27);
    text-align: center;
    gap:10px;
}

.icon-style{
    font-size: 40px;
}
.leader-container{
    background-color: rgb(8, 73, 134);
    color: white
}
.text-style{
    color: rgb(8, 73, 134);
    margin-left: 40px;
    letter-spacing: 15px;
}
.logo-container{
    width: 100px;
    height: 100px;
    

}
.header-container{
    margin: 30px 5px 0px 15px;
   
}
.header-style{
    margin-right: 10px;
}
.card-container{
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    margin:5px;
    box-shadow: 5px 5px 2px rgb(229, 233, 238);
    
}
.bg-image{
    height: 100vh;
    background-image: url(../../images/cover-4.jpg);
    background-attachment: fixed;
    background-size: cover;
}
.services-work{
    text-align: center;
    margin-top: 50px;
    padding-top: 80px;
}
.btn1, .btn2{
    padding: 15px 30px;
    border: none;
    gap: 6px;
    border-radius: 5px;
    
}
.btn1{
    /* background-color: #FF8F27; */
    background-color: white;
    color:#0e1c50;
    margin-right: 10px;
    font-weight: bold;
    
}

.btn1:hover{
    background-color: #FF8F27;
    color:white;
}

.btn2{
    background:none;
    border: 1px solid #FF8F27;
    color:white;
}
.btn2:hover{
    background-color: #FF8F27;
    color:white;
    transition: 1s;
}
.gallery{
    text-align: center;
    padding: 30px 30px 0px 30px;
    letter-spacing: 15px;
}
.horizontal-style{
    width: 40%;
    margin-left: 400px;
    border:1px solid white;
    margin-bottom: 30px;

}
.gallery-img{
    width:100%;
    height:200px;
    padding-bottom: 10px;
}
.leader-img-style{
    height: 700px;
}
.progress-container{
    height: 25px;
    background-color: white;
    border-radius: 7px;
    position: relative;
    
}
.progress-container .progress-bar{
    position:absolute;
    height:25px;
    border-radius: 7px;
    background-color: blueviolet;
    animation: progress-animation 5s forwards;
}

@keyframes progress-animation{
    0%{width: 0%;}
    100%{width: 100%;}
}

.card-style{
    /* margin-bottom: 10px; */
    text-align: center;
    width: 380px;
    height: 190px;
    transition:1s;
    background-color: white;
}
.card-style2{
    /* margin-bottom: 10px; */
    text-align: center;
    width: 380px;
    height: 190px;
    transition:1s;
    background-color: rgb(8, 8, 8, 0.9);
    color:white;
   
}
.card-style:hover{
    background-color: rgb(8, 8, 8, 0.9);
    color:white;
}



.video-btn{
    border: none;
    border-radius: 40px;
    padding: 25px 30px;
}
.play-btn{
    color:#FF8F27;
    font-size: 25px;
}




