.main-work{
    background-color: white;
    padding: 20px;
    border-radius: 10px;

}
.work-img{
   border: 1px solid gray;
    margin: 10px;
    width: 200px;
    height: 200px;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.infoWork-img{
    padding-left: 40px;
    transition:2s;
}
.workSystem-container{
    width: 100%;
    /* border: 1px solid red; */
    text-align: left;
    
}
.workInfo-container{
    padding-left: 50px;
    padding-right: 50px;
}
.workInfo-container h6{
    color: #0e1c50;
    font-weight: bold;
}
.custom-xavier-color{
    color: #0e1c50;
}
.custom-xavier-btn{
    background-color: #0e1c50;
    color:white;
    padding: 5px 20px;
    border-radius: 10px;
}
.workFeature-container{ 
    /* width:30%; */
    padding-left: 50px;
    background-color: #0e1c50;
    border-radius: 10px;
    color: white;
    text-align: left;
    
    padding-top: 80px;
}

.workFeature-container li{

    list-style-type: circle;
    
}
.work-btn{
    padding: 10px;
    width: 90%;
    border: none;
    color:#0e1c50;
    font-weight: bold;
    border-radius: 30px;
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
}
.work-hr{
    width: 80%;
}
