.bg-contact{
    background-color: rgb(235, 236, 236);
}

.contact-container{
    justify-content: center;
    grid-gap: 20px;
}

.contact-card{
    background-color: rgb(238, 232, 232);
    /* width: 400px; */
    width: 100%;
    height: 200px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.contact-card1{
    background-color: white;
    /* width: 400px; */
    width: 100%;
    height: 200px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 1px 1px #ffffff;
}
.contact-card:hover .contact-icons{
    rotate: 360deg;
    transition: 2s;
}
.contact-card1:hover .contact-icons{
    rotate: 360deg;
    transition: 2s;
}
.contact-card:hover .contact-icons{
    background-color: rgb(12, 12, 75);
    transition: 1s;
}
.contact-card1:hover .contact-icons{
    background-color: rgb(12, 12, 75);
    transition: 1s;
}

.contact-icons{
    font-size: 25px;
    color:#FF8F27;
    
    padding: 23px 25px;
    border-radius: 60px;
}


/* form */
.contact-img{
    width: 100%;
    height: 500px;
}

.form-width{
    width: 49%;
}
.contact-btn{
    
    background: rgb(30,48,80);
    color: white;
    font-size: 55px;
    font-weight: bold;
    width: 30%;
    font-size: 20px;
    border: 1px solid black;
    
    
}
.contact-btn:hover{
    color:white;
    transition: 2s;
    background-size: 200% 100%;
  

}
.contact-font{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    background-color:rgb(30,48,80);
    color:white;
    padding:  8px 10px;
    border-radius: 10px;

}

/* map */
.map-responsive {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .map-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  